import React, {useState, useEffect} from 'react'
import {isEmpty,size} from 'lodash'
import { addDocument, deleteDocument, getCollection ,updateDocument} from './actions'



function App() {
  const [task, setTask] = useState("")
  const [tasks,setTasks] = useState([]) 
  const [editMode,setEditMode] = useState(false)
  const [id,setId] =useState("")
  const [error, setError] = useState(null)

  useEffect(() => {
    (async()=>{
      const result = await getCollection('tasks')
      if (result.statusResponse){
        setTasks(result.data)
      }
    })()
  }, [])

  const validForm = () => {
    let isValid=true
    setError(null)
    if (isEmpty(task)){
      setError("You must enter a TaskName")
      isValid=false
    }    
    return isValid
  }

  const addTask = async (e) =>{
    e.preventDefault()

    if (!validForm()){
      return
    }
    
    const result = await addDocument("tasks",{name:task})
    if (!result.statusResponse){
      setError(result.error)
      return
    }

    setTasks([...tasks,{id:result.data.id,name:task}])
    setTask("")
  }
  const deleteTask = async(id) =>{
    const result = await deleteDocument("tasks",id)
    if (!result.statusResponse){
      setError(result.error)
      return
    }
    const filteredTasks = tasks.filter(task => task.id !== id )
    setTasks(filteredTasks)
  }
  const editTask = (_task) => {
    
    setTask(_task.name)
    setEditMode(true)
    setId(_task.id)

  }

  const saveTask = async (e) =>{
    e.preventDefault()
    if (!validForm()){
      return
    }

    const result = await updateDocument("tasks",id,{name:task})
    if (!result.statusResponse){
      setError(result.error)
      return
    }
    const editedTasks =tasks.map(item => item.id === id ? {id,name:task} : item)
    setTasks(editedTasks)
    setEditMode(false)
    setTask("")
    setId("")
  }

  return (
   <div className="container mt-5">
     <h1>Tasks</h1>
     <hr/>
     <div className="row">
        <div className="col-8">
          <h4 className="text-center">Lista de Tareas</h4>
          
          { 
            (size(tasks)>0) ?       
            (
              <ul className="list-group">
                {
                  tasks.map((task) =>(
                    <li className="list-group-item" key={task.id}>
                      <span 
                        className="lead"
                      >
                        {task.name}
                      </span>
                      <button 
                        className="btn btn-danger btn-sm float-right mx-2"
                        onClick={()=> deleteTask(task.id)}
                      >
                        Delete
                      </button>
                      <button 
                        className="btn btn-warning btn-sm float-right"
                        onClick={()=> editTask(task)}
                      >
                        Edit
                      </button>
                    </li>
                  ))
                }
              </ul>
            ) : (
              <ul className="list-group">
                <li className="list-group-item text-center">There is no Tasks to show</li>
              </ul>
            )
                
          }
        </div>
       <div className="col-4">
       <h4 className="text-center">
         {editMode?"Edit Task":"Add New Task"}
       </h4>
        <form onSubmit={editMode?saveTask:addTask}>
          {
            error && <span className="text-danger mb-2">{error}</span>
          }
          <input
            type="text"
            className="form-control mb-2"
            placeholder="Task Name ..."
            onChange={(text) => setTask(text.target.value)}
            value={task}
          />
          <button className={editMode?"btn btn-warning btn-block":"btn btn-dark btn-block"} type="submit">
            {editMode?"Save Changes":"Add new Task"}
          </button>
        </form>
       </div>
     </div>
   </div>
  )
}

export default App;
