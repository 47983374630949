import firebase from 'firebase/app'
import 'firebase/firestore'
const firebaseConfig = {
    apiKey: "AIzaSyCaC4dDhtnOUUdxtRY2b2n14zm02wvC8fw",
    authDomain: "crud-fb779.firebaseapp.com",
    projectId: "crud-fb779",
    storageBucket: "crud-fb779.appspot.com",
    messagingSenderId: "660927799221",
    appId: "1:660927799221:web:c1035713d68372cb8503fc"
  };
export const firebaseApp = firebase.initializeApp(firebaseConfig)
